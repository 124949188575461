/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const theme = createMuiTheme({
    palette: {
      // type: prefersDarkMode ? 'dark' : 'light',
      type: 'dark',
    },
  })

  // toggleEasterEgg = () => {
  //   let setEasterEggIndex = this.state.easterEggIndex + 1;
  //   let easterEggCollection = [
  //     ["WITH", "LOVE"],
  //     ["BRING", "PEACE"],
  //     ["HONOUR", "NATURE"],
  //     ["PROTECT", "FREEDOM"],
  //     ["MANIFEST", "FAIRNESS"],
  //     ["EMPOWER", "BRAVERY"],
  //     ["CREATE", "AMENDS"],
  //     ["CARRY", "MERCY"]
  //   ]
  //   if(!easterEggCollection[setEasterEggIndex]){
  //     setEasterEggIndex = 0;
  //   }
  //   this.setState({
  //     easterEggIndex: setEasterEggIndex,
  //     easterEggText: easterEggCollection[setEasterEggIndex]
  //   })
  // }

  return (
    <ThemeProvider theme={theme}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer>
          <h5 style={{color:'white', textAlign:'center'}}><span style={{fontSize: 35}}><a className={"hidden-link mid-opacity-hover"} href="https://society0x.org">⎊</a></span></h5>
          <h6 style={{color:'white', textAlign:'center'}}><span className={"low-opacity-hover cursor-auto"}>FOLLOW YOUR HEART</span></h6>
          <h5 style={{color:'white', textAlign:'center'}}><span style={{fontSize: 35}}><a className={"hidden-link mid-opacity-hover"} href="https://society0x.org">⎊</a></span></h5>
        </footer>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
