import React from "react"
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const BlogPostHeader = ({title, subTitle, imgSrc}) => {
    return (
        <>
            <Typography style={{marginBottom: `0.5rem`}} className={"white-monospace"} variant="h4" component="h2">
                {title}
            </Typography>
            <Typography style={{marginBottom: `1rem`}} className={"white-monospace"} variant="h5" gutterBottom={true} component="h2">
                {subTitle}
            </Typography>
            {imgSrc && 
                <Card style={{marginBottom: `1rem`}}>
                    <CardMedia
                        component="img"
                        alt={`Blog Post Featured Image for ${title}`}
                        height="250"
                        image={imgSrc}
                        title={title}
                        style={{
                            pointerEvents: 'none'
                        }}
                    />
                </Card>
            }
        </>
    )
}

export default BlogPostHeader
