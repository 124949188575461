import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { withStyles } from '@material-ui/core/styles';
import Image from "../components/image"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    overflow: "hidden",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 5,
  },
  accountAddressStyle: {
    color: "white",
    display: "inline-block"
  },
  appBarProfilePic: {
    maxWidth: "45px",
  },
  appBarProfilePicContainer: {
    paddingTop: "0px",
    paddingBottom: "0px",
  }
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteTitle: props.siteTitle,
      anchorEl: null,
    };
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleMenu = () => {
      // debounce(refreshCurrentDonationProgressViaServer(), 250);
      // this.props.dispatch(showLeftMenu(!this.state.showLeftMenu));
  }

  render() {
    const { siteTitle, classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar position="static" className="transparent our-gradient">
          <Toolbar>
          </Toolbar>
        </AppBar>
        <AppBar position="fixed" className="our-gradient">
          <Toolbar>
            {/* <IconButton onClick={() => this.toggleMenu()} className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton> */}
            <div className={["logo-container", classes.grow].join(" ")}>
              <Link className="no-highlight-on-mobile-link-element-click" to="/">
                <Typography className={"white-monospace"} variant="h4" component="h2">
                  philosophy0x
                </Typography>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withStyles(styles)(Header);